import { FC } from 'react';
import { Icons, WorkplannerCard } from 'team-hero-ui';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { useElementSize } from 'hooks/useElementSize';
import { useDateFormat } from 'hooks/useDateFormat.hook';
import {
  IProjectShiftWorkplanner,
  TProjectShiftStatus,
} from 'interfaces/ProjectShift.interface';
import { getOutputNumber } from 'helpers/number/numberToString.helper';
import { checkSpansFullDay } from 'helpers/dateTime/startOfEndOf.helper';
import { ITimelineShiftItemCollectionProps } from 'components/Timeline/interfaces/timeline.interface';
import { getDurationAsHoursByMoment } from 'components/Timeline/helper/timelineItemsCalculate.helper';
import {
  getShiftsWithContactsFromShifts,
  sortShiftsByStatus,
} from 'components/Timeline/helper/shiftTimeline.helper';
import { getCombinedShiftStatus } from 'helpers/shifts/shiftStatusCount.helper';
import {
  TimelineItemAvatarListWrapper,
  TimelineItemInnerBottomStyled,
  TimelineItemInnerStyled,
  TimelineItemInnerTopStyled,
  TimelineTimeDetailStyled,
  TimelineItemAvatarMoreIndicatorStyled,
  ConnectedIconWrapperStyled,
  TimelineTimeDetailTextStyled,
} from '../itemRenderer.styled';
import AvatarStatusRenderer from '../AvatarStatusRenderer';
import useClientLoginMissionSettings from 'hooks/useClientLoginMissionSettings.hook';

const TimelineItemProjectShiftCollection: FC<
  ITimelineShiftItemCollectionProps
> = ({ timelineItem, left, cellWidth, top, height, missionId }) => {
  const { t } = useTranslation('general');
  const { formatDate } = useDateFormat();

  const { enableClientContacts } = useClientLoginMissionSettings({ missionId });

  const { id, start, end, item: shiftCollection } = timelineItem;

  const duration = getDurationAsHoursByMoment(start, end);

  const shiftAmount = shiftCollection.length;

  const combinedStatus: TProjectShiftStatus =
    getCombinedShiftStatus(shiftCollection);

  const shiftsWithContacts: IProjectShiftWorkplanner[] = sortShiftsByStatus(
    getShiftsWithContactsFromShifts(shiftCollection)
  );

  const [avatarWrapperRef, { width }] = useElementSize();

  const avatarWrapperRightOffset = 80;
  const additionalInfoOffset = 22;
  const itemSize = 35;
  const shiftLimit: number =
    (width - (avatarWrapperRightOffset + additionalInfoOffset)) / itemSize;

  const durationDays = Math.floor(
    moment.duration(moment(end).diff(moment(start).startOf('day'))).asDays()
  );

  return (
    <WorkplannerCard
      status={'deactivated'}
      innerMinWidth={220}
      left={left}
      width={cellWidth}
      top={top}
      height={height}
      key={id}
    >
      <TimelineItemInnerStyled>
        <TimelineItemInnerTopStyled>
          <span>
            {shiftAmount}x {getOutputNumber(duration)}
            {t('hourShort')}
          </span>
          <span>
            {getOutputNumber(shiftAmount * duration)}
            {t('hourShort')}
          </span>
        </TimelineItemInnerTopStyled>
        <TimelineTimeDetailStyled>
          <TimelineTimeDetailTextStyled>
            {durationDays > 0 && (
              <>
                {formatDate(start, 'dateShort')} -{' '}
                {formatDate(end, 'dateShort')}{' '}
              </>
            )}
            {!checkSpansFullDay(start, end) ? (
              <>
                {formatDate(start, 'time')} - {formatDate(end, 'time')}
              </>
            ) : (
              <>&nbsp;</>
            )}
          </TimelineTimeDetailTextStyled>
          {shiftCollection[0].linkedPosition && (
            <ConnectedIconWrapperStyled>
              <Icons.ConnectedIcon svgSize={14} svgColor='currentColor' />
            </ConnectedIconWrapperStyled>
          )}
        </TimelineTimeDetailStyled>
        <TimelineItemInnerBottomStyled ref={avatarWrapperRef}>
          {enableClientContacts && (
            <TimelineItemAvatarListWrapper>
              {shiftsWithContacts.slice(0, shiftLimit).map((shift) => (
                <AvatarStatusRenderer
                  key={shift.id}
                  shift={shift}
                  cardStatus={combinedStatus}
                />
              ))}
              {shiftsWithContacts.slice(shiftLimit).length > 0 && (
                <TimelineItemAvatarMoreIndicatorStyled>
                  +{shiftsWithContacts.slice(shiftLimit).length}
                </TimelineItemAvatarMoreIndicatorStyled>
              )}
            </TimelineItemAvatarListWrapper>
          )}
        </TimelineItemInnerBottomStyled>
      </TimelineItemInnerStyled>
    </WorkplannerCard>
  );
};

export default TimelineItemProjectShiftCollection;
