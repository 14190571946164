import { FC } from 'react';

import Avatar from 'components/Avatar';
import {
  ApplicationCardDefaultStyled,
  ApplicationDetailsStyled,
  ApplicationNameStyled,
} from './ApplicationCardDefault.styled';
import type { IApplicationCardDefaultProps } from './ApplicationCardDefault.types';

const ApplicationCardDefault: FC<IApplicationCardDefaultProps> = ({
  application,
}) => {
  return (
    <ApplicationCardDefaultStyled>
      <Avatar
        sourceType='file'
        width={40}
        images={application.contact.avatar || ''}
        firstName={application.contact.firstName}
        lastName={application.contact.lastName}
        skipLoading
        thumbnailSize='small'
      />
      <ApplicationDetailsStyled>
        <ApplicationNameStyled>
          {application.contact.firstName} {application.contact.lastName}
        </ApplicationNameStyled>
      </ApplicationDetailsStyled>
    </ApplicationCardDefaultStyled>
  );
};

export default ApplicationCardDefault;
