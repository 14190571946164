import {
  Avatar as AvatarImg,
  AvatarStatus,
  Loader,
  Theme,
  Tooltip,
} from 'team-hero-ui';
import { useMemo } from 'react';

import type { IAvatarProps } from './Avatar.types';
import useGetImageUrl from 'hooks/useGetImageUrl.hook';

const Avatar = <T,>({
  images,
  width,
  sourceType,
  additionalRefreshTrigger,
  showStatus = false,
  status = 'default',
  firstName,
  lastName,
  skipLoading = false,
  embeddedContact,
  color,
  bgColor,
  onClick,
  thumbnailSize,
  icon,
}: IAvatarProps<T>): JSX.Element => {
  const { imageUrls, isLoading } = useGetImageUrl({
    images,
    imageType: sourceType,
    additionalRefreshTrigger,
    thumbnailSize,
  });

  const getFirstName = useMemo((): string | null => {
    if (firstName) {
      return firstName;
    }
    if (embeddedContact) {
      return embeddedContact.firstName;
    }
    return null;
  }, [embeddedContact, firstName]);

  const getLastName = useMemo((): string | null => {
    if (lastName) {
      return lastName;
    }
    if (embeddedContact) {
      return embeddedContact.lastName;
    }
    return null;
  }, [embeddedContact, lastName]);

  if (isLoading && !skipLoading) {
    return <Loader loaderType='static' loaderSize='tiny' />;
  }

  const url = Array.isArray(imageUrls) ? imageUrls[0] : imageUrls;

  return showStatus ? (
    <AvatarStatus
      width={width}
      image={url}
      status={status}
      color={color}
      firstName={getFirstName || undefined}
      lastName={getLastName || undefined}
      avatarOnClick={onClick}
      bgColor={
        embeddedContact?.isExternal ? Theme.colors.primary.grey : bgColor
      }
    />
  ) : (
    <Tooltip
      title={embeddedContact?.provider?.name || ''}
      styleType='top'
      bgColor={Theme.colors.primary.grey}
      textColor={Theme.colors.primary.white}
    >
      <div>
        <AvatarImg
          firstName={getFirstName || undefined}
          lastName={getLastName || undefined}
          width={width}
          images={imageUrls}
          avatarOnClick={onClick}
          bgColor={
            embeddedContact?.isExternal ? Theme.colors.primary.grey : bgColor
          }
          icon={icon}
        />
      </div>
    </Tooltip>
  );
};

export default Avatar;
