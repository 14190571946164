import {
  ISettingsSystem,
  ITimesheetCategories,
  ITimetrackingCategories,
} from 'interfaces/SystemSettings.interface';
import { TCurrencyName } from 'services/currency/currency.types';
import { useGetSystemSettingsQuery } from 'services/teamHeroApi.service';

interface IUseGetSystemSettingsReturn {
  systemSettings?: ISettingsSystem;
  preferredFields: string[];
  currency: TCurrencyName;
  supportUrl: string;
  checkinRange: number;
  timetrackingMaxHours: number;
  jobNotifications: {
    notifyOn: number[];
    notifyAt?: number;
    daysCount?: number;
  };
  isLoading: boolean;
  timetrackingRounding?: number;
  timesheetCategories?: ITimesheetCategories;
  timetrackingCategories?: ITimetrackingCategories;
}

export const useGetSystemSettings = (
  skip = false
): IUseGetSystemSettingsReturn => {
  const { data: settingsData, isLoading } = useGetSystemSettingsQuery(
    undefined,
    { skip }
  );

  const preferredFields = settingsData?.settings.preferred_fields || [];
  const currency = settingsData?.settings.currency || 'EUR';
  const supportUrl = settingsData?.settings.support_url?.hero || '';
  const checkinRange = settingsData?.settings.shift_checkin_range || 0;
  const timetrackingRounding = settingsData?.settings.timetracking_rounding;
  const jobsNotifications = settingsData?.settings.jobs_notifications;
  const timetrackingMaxHours =
    settingsData?.settings.time_tracking_max_hours || Number.MAX_SAFE_INTEGER;
  const timesheetCategories = settingsData?.settings.timesheet_categories;
  const timetrackingCategories =
    settingsData?.settings.time_tracking_categories;

  const jobNotifications = {
    notifyOn: jobsNotifications?.notify_on || [],
    notifyAt: jobsNotifications?.notify_at,
    daysCount: jobsNotifications?.upcoming_days_count,
  };

  return {
    systemSettings: settingsData?.settings,
    preferredFields,
    currency,
    supportUrl,
    checkinRange,
    isLoading,
    jobNotifications,
    timetrackingMaxHours,
    timetrackingRounding,
    timesheetCategories,
    timetrackingCategories,
  };
};
