import { FC, useEffect, useState } from 'react';
import { ConversationHeader, Icons, Loader, Theme } from 'team-hero-ui';
import { useTranslation } from 'react-i18next';

import type { IClientLoginConversationPanelProps } from './ClientLoginConversationPanel.types';
import useCurrentUserContact from 'hooks/useCurrentUserContact.hook';
import { useGetMessageConversationsQuery } from 'services/teamHeroApi.service';
import {
  ConversationListStyled,
  ConversationPanelStyled,
  KeyAccountStyled,
  NewConversationActionCardStyled,
  PhoneNumberStyled,
} from './ClientLoginConversationPanel.styled';
import { createPaginationApiArgs } from 'services/helper/getPaginationParams.helper';
import CLientLoginConversationList from '../ClientLoginConversationList/ClientLoginConversationList';
import ClientLoginConversation from '../ClientLoginConversation/ClientLoginConversation';
import { paginatedConversationsSelector } from 'redux/collections/conversations/conversations.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import DashboardActionCard from '../DashboardActionCard';
import Avatar from 'components/Avatar';
import { setMessagesDetails } from 'redux/messages/messages.slice';

const ClientLoginConversationPanel: FC<IClientLoginConversationPanelProps> = ({
  defaultView = 'conversationsList',
  leader,
  isLoadingLeader,
  conversationsFilter,
  conversationType,
  mission,
  height = '100dvh',
}) => {
  const { t } = useTranslation('general');

  const dispatch = useAppDispatch();

  const { currentUserContact } = useCurrentUserContact();

  const [selectedConversationId, setSelectedConversationId] =
    useState<number>();

  const [isNewConversation, setIsNewConversation] = useState<boolean>(false);

  const { pagination, sorting } = useAppSelector(
    paginatedConversationsSelector
  );

  const {
    data: conversationsData,
    isLoading: isLoadingConversations,
    isError,
  } = useGetMessageConversationsQuery(
    {
      participant: currentUserContact?.id,
      order: { 'order[lastMessage.createdAt]': 'desc' },
      ...createPaginationApiArgs(pagination, sorting),
      filters: conversationsFilter,
    },
    { skip: !currentUserContact?.id }
  );

  // Reset messages details state on unmount
  useEffect(() => {
    return () => {
      dispatch(setMessagesDetails({}));
    };
  }, [dispatch]);

  // Set first conversation as selected if default view should be conversation details
  useEffect(() => {
    if (defaultView === 'conversation' && conversationsData?.items?.length) {
      setSelectedConversationId(conversationsData.items[0].id);
    } else {
      setSelectedConversationId(undefined);
      setIsNewConversation(false);
    }
  }, [conversationsData, defaultView]);

  if (isError) {
    throw new Error('Error fetching conversations');
  }

  const onCloseConversation = () => {
    setSelectedConversationId(undefined);
    setIsNewConversation(false);
  };

  const onCloseNewConversation = () => {
    onCloseConversation();
    dispatch(setMessagesDetails({}));
  };

  const onConversationClick = (conversationId: number) => {
    setSelectedConversationId(conversationId);
  };

  const onStartConversationClick = () => {
    if (leader) {
      dispatch(
        setMessagesDetails({
          contact: leader,
          isCreateNewConversation: true,
          mission,
        })
      );
      setIsNewConversation(true);
    }
  };

  const isLoading = isLoadingConversations || isLoadingLeader;

  const hasConversations = !isLoading && !!conversationsData?.items?.length;

  if (!hasConversations && !leader) {
    return null;
  }

  const ConversationsHeader = () => {
    return (
      <ConversationHeader
        leftSideElements={
          <>
            <Avatar
              sourceType='file'
              width={40}
              images={leader?.avatar || ''}
              firstName={leader?.firstName}
              lastName={leader?.lastName}
              skipLoading
            />
            <KeyAccountStyled>
              <p>
                {leader?.firstName} {leader?.lastName}
              </p>
              <p>
                {leader
                  ? t(`details.${conversationType}`)
                  : t('details.noKeyAccount')}
              </p>
            </KeyAccountStyled>
          </>
        }
        rightSideElements={
          <PhoneNumberStyled>
            {leader?.phone && (
              <>
                <Icons.TelephoneIcon svgSize={20} />
                <p>{leader?.phone}</p>
              </>
            )}
          </PhoneNumberStyled>
        }
      />
    );
  };

  return (
    <ConversationPanelStyled $height={height}>
      {isLoading ? (
        <Loader />
      ) : selectedConversationId || isNewConversation ? (
        <>
          {isNewConversation ? <ConversationsHeader /> : null}
          <ClientLoginConversation
            conversationId={selectedConversationId}
            isNewConversation={isNewConversation}
            onClose={onCloseConversation}
            onCloseNewConversation={onCloseNewConversation}
          />
        </>
      ) : hasConversations ? (
        <ConversationListStyled>
          <ConversationsHeader />
          <CLientLoginConversationList
            conversations={conversationsData.items}
            conversationsTotalCount={conversationsData.totalCount}
            onConversationClick={onConversationClick}
          />
        </ConversationListStyled>
      ) : (
        <NewConversationActionCardStyled>
          <DashboardActionCard
            type={`${conversationType}Conversation`}
            icon={
              <Icons.MessageUniversalIcon
                svgColor={Theme.colors.greyShades.grey4}
                svgSize={100}
              />
            }
            onActionButtonClick={onStartConversationClick}
          />
        </NewConversationActionCardStyled>
      )}
    </ConversationPanelStyled>
  );
};

export default ClientLoginConversationPanel;
