export const getOutputNumber = (value?: number, maxDigits = 1): string => {
  if (value) {
    return value.toLocaleString('de-DE', {
      maximumFractionDigits: maxDigits,
    });
  }
  return '';
};

export const getPercentageString = (
  numberOne: number,
  numberTwo: number,
  maxDigits = 0
): string => {
  if (numberTwo <= 0 || numberOne <= 0) {
    return '0%';
  }
  return `${getOutputNumber((numberOne / numberTwo) * 100, maxDigits)}%`;
};
