import { FC, ReactElement } from 'react';
import { device, Utils } from 'team-hero-ui';
import Flag from 'react-world-flags';

import Avatar from 'components/Avatar';
import {
  AvatarWrapperStyled,
  ContactHeaderAvatarStyled,
  NationalityContainerStyled,
} from './AvatarSection.styled';
import { IAvatarSectionProps } from './AvatarSection.types';

const { useMediaQuery } = Utils;

const AvatarSection: FC<IAvatarSectionProps> = ({
  images,
  avatar,
  nationality,
  firstName,
  lastName,
  onEditButtonClick,
}: IAvatarSectionProps): ReactElement => {
  const isLgScreenSize = useMediaQuery(device.lg);
  return (
    <ContactHeaderAvatarStyled>
      <AvatarWrapperStyled>
        <Avatar
          sourceType='resource'
          width={180}
          images={images || ''}
          additionalRefreshTrigger={avatar}
          firstName={firstName}
          lastName={lastName}
          onClick={onEditButtonClick}
          thumbnailSize={'medium'}
        />
      </AvatarWrapperStyled>
      {isLgScreenSize && nationality && (
        <NationalityContainerStyled>
          <Flag height={16} code={nationality.toLowerCase()} />
          <span>{nationality}</span>
        </NationalityContainerStyled>
      )}
    </ContactHeaderAvatarStyled>
  );
};

export default AvatarSection;
