import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, Icons, NumberRange, Theme } from 'team-hero-ui';

import { IFilterRendererProps } from 'components/FilterPanel/FilterPanel.type';
import {
  FilterElementStyled,
  FilterLabelStyled,
  FilterLeftElementStyled,
} from 'components/FilterPanel/filterRenderers/FilterElement.styled';
import { FromNumberRendererStyled } from './FromToNumberRenderer.styled';

export const FromToNumberRenderer: FC<IFilterRendererProps> = ({
  label,
  onChange,
  value,
  styleType,
}) => {
  const { t } = useTranslation();
  return (
    <FromNumberRendererStyled>
      {label && (
        <FilterLeftElementStyled>
          <FilterLabelStyled>{label}</FilterLabelStyled>
        </FilterLeftElementStyled>
      )}
      <FilterElementStyled>
        <NumberRange
          key='fromToNumber'
          onChange={(fromDate, toDate) =>
            onChange({
              from: fromDate,
              to: toDate,
            })
          }
          styleType={styleType === 'modal' ? 'modal' : 'modalBright'}
          from={value?.from ?? ''}
          to={value?.to ?? ''}
          placeholderFrom={t('modal.labels.from')}
          placeholderTo={t('modal.labels.to')}
        />
        <IconButton size='small' onClick={() => onChange({ from: '', to: '' })}>
          <Icons.CloseIcon
            svgSize={16}
            svgColor={
              styleType === 'modal'
                ? Theme.colors.primary.white
                : Theme.colors.primary.grey
            }
          />
        </IconButton>
      </FilterElementStyled>
    </FromNumberRendererStyled>
  );
};
