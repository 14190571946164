import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Icons, Theme } from 'team-hero-ui';

import {
  checkEmbedIsApplication,
  checkEmbedIsSystemContact,
} from 'helpers/embed/embedTypeCheck.helper';
import Avatar from 'components/Avatar';
import { EmptyCell } from 'components/cellRenderers/EmptyCellRenderer/EmptyCellRenderer';
import { ContactCellContainerStyled } from './ContactCellRenderer.styled';
import { ICustomContactCellRendererProps } from './ContactCellRenderer.types';
import { CommonCellContentStyled } from '../Common.styled';
import type { IContact } from 'interfaces/Contact.interface';

const ContactCellRenderer: FC<ICustomContactCellRendererProps> = ({
  contactData,
  width,
  displayName = true,
  fontSize,
  onClick,
}) => {
  const { t } = useTranslation('general');

  if (!contactData) {
    return <EmptyCell />;
  }

  if (checkEmbedIsSystemContact(contactData)) {
    return (
      <ContactCellContainerStyled>
        <Avatar
          sourceType='resource'
          images={''}
          width={width}
          bgColor={Theme.colors.greyShades.grey5}
          thumbnailSize={'small'}
          icon={(avatarWidth) => (
            <Icons.LogoIcon
              svgColor={Theme.colors.greyShades.grey2}
              svgSize={avatarWidth * 0.75}
            />
          )}
          onClick={onClick}
        />
        {displayName && (
          <CommonCellContentStyled $fontSize={fontSize}>
            {t('common.systemContact')}
          </CommonCellContentStyled>
        )}
      </ContactCellContainerStyled>
    );
  }

  if (checkEmbedIsApplication(contactData)) {
    return (
      <ContactCellContainerStyled>
        <div>
          <Avatar
            sourceType='file'
            images={contactData.avatar || ''}
            width={width}
            firstName={contactData.firstName}
            lastName={contactData.lastName}
            thumbnailSize={'small'}
            onClick={onClick}
          />
        </div>
        {displayName && (
          <CommonCellContentStyled $fontSize={fontSize}>
            {contactData.firstName} {contactData.lastName}
          </CommonCellContentStyled>
        )}
      </ContactCellContainerStyled>
    );
  }

  return (
    <ContactCellContainerStyled>
      <div>
        <Avatar
          sourceType='resource'
          images={contactData.avatar || ''}
          width={width}
          embeddedContact={contactData}
          bgColor={
            (contactData as IContact).isExternal
              ? Theme.colors.primary.black
              : undefined
          }
          thumbnailSize={'small'}
          onClick={onClick}
        />
      </div>
      {displayName && (
        <CommonCellContentStyled $fontSize={fontSize}>
          {contactData.firstName} {contactData.lastName}
        </CommonCellContentStyled>
      )}
    </ContactCellContainerStyled>
  );
};

export default ContactCellRenderer;
