import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type { IProjectShift } from 'interfaces/ProjectShift.interface';
import { useAppSelector } from 'store/hooks';
import { useGetProjectShiftsQuery } from 'services/teamHeroApi.service';
import { createPaginationApiArgs } from 'services/helper/getPaginationParams.helper';
import { usePagination } from 'hooks/usePagination.hook';
import { useSort } from 'components/TableView/hooks/useSort.hook';
import type { ISimpleFilter } from 'hooks/useFilters.hook';
import {
  paginatedProjectShiftsSelector,
  setItemsPerPage,
  setNextPage,
  setSorting,
  setMenuColumnList,
} from 'redux/table/projectShifts/projectShifts.slice';
import TableViewWorkplanner from 'components/TableView/TableViewWorkplanner';
import { IWorkPlannerTableProps } from './WorkPlannerTable.type';
import { useRowSelection } from 'components/TableView/hooks/useRowSelection.hook';
import {
  columnsOffset,
  shiftsColumnsFactory,
} from 'config/tableColumnConfigurations/ShiftsColumns.config';
import { getDuringStringHelper } from 'helpers/dateTime/getDuringString.helper';
import useClientLoginMissionSettings from 'hooks/useClientLoginMissionSettings.hook';

const WorkPlannerTable: FC<IWorkPlannerTableProps> = ({
  mission,
  start,
  end,
}) => {
  const { t } = useTranslation(['general', 'errors']);

  const { enableClientContacts } = useClientLoginMissionSettings({
    missionId: mission.id,
  });

  const { pagination, sorting, filters } = useAppSelector(
    paginatedProjectShiftsSelector
  );

  const shiftFilters = useMemo((): ISimpleFilter[] => {
    return [
      { key: 'mission', operator: 'AND', value: mission.id },
      {
        key: 'during',
        operator: 'AND',
        value: getDuringStringHelper(start.toDate(), end.toDate()),
      },
      ...filters,
    ];
  }, [end, filters, mission.id, start]);

  const {
    isLoading,
    isFetching,
    isError,
    data: shifts,
  } = useGetProjectShiftsQuery({
    ...createPaginationApiArgs(pagination, sorting),
    filters: shiftFilters,
  });

  const paginationData = usePagination(
    1,
    setNextPage,
    setItemsPerPage,
    paginatedProjectShiftsSelector
  );

  const sortingData = useSort(setSorting, paginatedProjectShiftsSelector);

  const { rowSelectionProps, bulkEdit } = useRowSelection<IProjectShift>(
    shifts?.items
  );

  if (isError) {
    throw new Error(t('errors:errorOccurred'));
  }

  return (
    <>
      <TableViewWorkplanner
        columns={shiftsColumnsFactory({
          t,
          enableClientContacts,
        })}
        data={shifts?.items || []}
        itemsTotalCount={shifts?.totalCount || 0}
        pagination={paginationData}
        columnsOffset={columnsOffset}
        sorting={sortingData}
        rowIdMenuOpen={rowSelectionProps.rowIdMenuOpen}
        bulkEditRow={bulkEdit}
        isMin={false}
        isLoading={isLoading}
        isFetching={isFetching}
        paginationSelector={paginatedProjectShiftsSelector}
        setColumnListDispatch={setMenuColumnList}
      />
    </>
  );
};

export default WorkPlannerTable;
