import { styled } from 'styled-components';
import { Theme, Utils } from 'team-hero-ui';

const { flexFn, pxToRem } = Utils;

export const ConversationStyled = styled.div`
  ${flexFn('flex-end', 'stretch', 'column')};
  background-color: ${Theme.colors.primary.white};
  height: 100%;
  flex-grow: 1;
`;

export const MessageListStyled = styled.div`
  height: 100%;
  overflow: auto;
  padding: ${pxToRem(16)};
  background-color: ${Theme.colors.primary.white};
`;

export const ScrollToBottomAnchor = styled.div`
  height: ${pxToRem(1)};
`;
