import { uniqBy } from 'lodash';

import { IShiftApplication } from 'interfaces/ShiftApplication.interface';
import type { IApplicationGroup } from '../MissionApplicationsTab.types';

export type TShiftGroupKey = 'position' | 'area' | 'task';

export const getGroupedApplications = (
  applications: IShiftApplication[]
): IApplicationGroup[] => {
  const result: IApplicationGroup[] = [];

  applications.forEach((application) => {
    const key = `${application.shift.position}-${application.shift.area}`;

    const existingData = result.find((el) => el.id === key);

    const isOpenApplication = application.clientStatus === 'open';

    if (existingData) {
      existingData.applications.push(application);
      if (isOpenApplication) {
        existingData.openApplications.push(application);
      }
    } else {
      const data = {
        id: key,
        position: application.shift.position,
        area: application.shift.area,
        applications: [application],
        openApplications: isOpenApplication ? [application] : [],
      };

      result.push(data);
    }
  });

  return result;
};

export const getApplicationIdsWithSameContact = (
  applications: IShiftApplication[],
  applicationId: number
): number[] => {
  const application = applications.find((el) => el.id === applicationId);

  if (!application) {
    return [];
  }

  const contactId = application.contact.id;

  const result = applications
    .filter((el) => el.contact.id === contactId)
    .map((el) => el.id);

  return result;
};

export const getUniqueApplications = (applications: IShiftApplication[]) =>
  uniqBy(applications, 'contact.id');
