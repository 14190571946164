import moment from 'moment';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TLocale } from 'interfaces/Locale.interface';
import { dateService, TDateFormatTypes } from 'services/date/date.service';

export type TFormatDateFunction = (
  date?: moment.Moment | string | Date,
  format?: TMomentFormats,
  roundEndOfDay?: boolean
) => string;

interface IUseDateFormateReturn {
  dateFormat: TDateFormatTypes;
  yesterdayFormat: TDateFormatTypes;
  lastWeekFormat: TDateFormatTypes;
  dateFormatYearShort: TDateFormatTypes;
  dayShortWithDateShort: TDateFormatTypes;
  dateTimeFormat: TDateFormatTypes;
  dayWithDate: TDateFormatTypes;
  dayShortWithDate: TDateFormatTypes;
  dayMonth: TDateFormatTypes;
  dateShort: TDateFormatTypes;
  timeFormat: TDateFormatTypes;
  dayShort: TDateFormatTypes;
  day: TDateFormatTypes;
  dayFull: TDateFormatTypes;
  yearFormat: TDateFormatTypes;
  formatDate: TFormatDateFunction;
}

export type TMomentFormats =
  | 'key'
  | 'short'
  | 'time'
  | 'dayFull'
  | 'dayShort'
  | 'dayTwoLetters'
  | 'dayLong'
  | 'dayWithDate'
  | 'datetimeYearFirst'
  | 'dateYearFirst'
  | 'dateTime'
  | 'dateWithDayNoYear'
  | 'dateShort'
  | 'dateYearShort'
  | 'dayShortWithDateShort'
  | 'fullTime'
  | 'day'
  | 'dayShortWithDate'
  | 'dayMonth'
  | 'hour'
  | 'milliseconds'
  | 'millisecondsLong'
  | 'seconds'
  | 'secondsLong'
  | 'minuteShort'
  | 'hourShort'
  | 'weekDayDetail'
  | 'month'
  | 'monthLong'
  | 'year'
  | 'yesterday'
  | 'lastWeek'
  | 'dayNumber'
  | 'date'
  | 'dateNoBreaks'
  | 'dateShortWithTime'
  | 'default';

export const useDateFormat = (): IUseDateFormateReturn => {
  const { i18n } = useTranslation();

  const dateWithDayNoYear: TDateFormatTypes = useMemo(() => {
    switch (i18n.languages[0] as TLocale) {
      case 'en':
        return 'ddd DD/MM';
      case 'de':
      default:
        return 'ddd DD.MM';
    }
  }, [i18n.languages]);

  const dateYearFirst: TDateFormatTypes = useMemo(() => {
    switch (i18n.languages[0] as TLocale) {
      case 'en':
        return 'YYYY/MM/DD HH:mm';
      case 'de':
      default:
        return 'YYYY.MM.DD HH:mm';
    }
  }, [i18n.languages]);

  const dateFormat: TDateFormatTypes = useMemo(() => {
    switch (i18n.languages[0] as TLocale) {
      case 'en':
        return 'DD/MM/YYYY';
      case 'de':
      default:
        return 'DD.MM.YYYY';
    }
  }, [i18n.languages]);

  const dateFormatYearShort: TDateFormatTypes = useMemo(() => {
    switch (i18n.languages[0] as TLocale) {
      case 'en':
        return 'DD/MM/YY';
      case 'de':
      default:
        return 'DD.MM.YY';
    }
  }, [i18n.languages]);

  const dateTimeFormat: TDateFormatTypes = useMemo(() => {
    switch (i18n.languages[0] as TLocale) {
      case 'en':
        return 'DD/MM/YYYY HH:mm';
      case 'de':
      default:
        return 'DD.MM.YYYY HH:mm';
    }
  }, [i18n.languages]);

  const dayWithDate: TDateFormatTypes = useMemo(() => {
    switch (i18n.languages[0] as TLocale) {
      case 'en':
        return 'dddd, DD/MM/YYYY';
      case 'de':
      default:
        return 'dddd, DD.MM.YYYY';
    }
  }, [i18n.languages]);

  const dayShortWithDate: TDateFormatTypes = useMemo(() => {
    switch (i18n.languages[0] as TLocale) {
      case 'en':
        return 'dd, DD/MM/YYYY';
      case 'de':
      default:
        return 'dd, DD.MM.YYYY';
    }
  }, [i18n.languages]);

  const dayShortWithDateShort: TDateFormatTypes = useMemo(() => {
    switch (i18n.languages[0] as TLocale) {
      case 'en':
        return 'dd, DD/MM';
      case 'de':
      default:
        return 'dd, DD.MM';
    }
  }, [i18n.languages]);

  const dayMonth: TDateFormatTypes = useMemo(() => {
    switch (i18n.languages[0] as TLocale) {
      case 'en':
        return 'DD/MM';
      case 'de':
      default:
        return 'DD.MM';
    }
  }, [i18n.languages]);

  const dateShort: TDateFormatTypes = useMemo(() => {
    switch (i18n.languages[0] as TLocale) {
      case 'en':
        return 'DD/MM';
      case 'de':
      default:
        return 'DD.MM';
    }
  }, [i18n.languages]);

  const yesterdayFormat: TDateFormatTypes = useMemo(() => {
    switch (i18n.languages[0] as TLocale) {
      case 'en':
        return '[Yesterday] HH:mm';
      case 'de':
      default:
        return '[Gestern] HH:mm';
    }
  }, [i18n.languages]);

  const lastWeekFormat: TDateFormatTypes = useMemo(() => {
    switch (i18n.languages[0] as TLocale) {
      case 'en':
        return '[Last] dddd HH:mm';
      case 'de':
      default:
        return '[Letzten] dddd HH:mm';
    }
  }, [i18n.languages]);

  const weekDetailFormat: TDateFormatTypes = useMemo(() => {
    switch (i18n.languages[0] as TLocale) {
      case 'en':
        return '[Week] w, MMMM YYYY';
      case 'de':
      default:
        return '[Woche] w, MMMM YYYY';
    }
  }, [i18n.languages]);

  const dateShortWithTime: TDateFormatTypes = useMemo(() => {
    switch (i18n.languages[0] as TLocale) {
      case 'en':
        return 'DD/MM HH:mm';
      case 'de':
      default:
        return 'DD.MM HH:mm';
    }
  }, [i18n.languages]);

  const displayAsMidnight = (time: string) => {
    const [hour, minute] = time.split(':');
    return hour === '23' && minute === '59' ? '24' : time;
  };

  const day: TDateFormatTypes = 'ddd';
  const dayTwoLetters: TDateFormatTypes = 'dd';
  const dayShort: TDateFormatTypes = 'ddd D';
  const dayFull: TDateFormatTypes = 'dddd';
  const timeFormat: TDateFormatTypes = 'HH:mm';
  const yearFormat: TDateFormatTypes = 'YYYY';
  const milliseconds: TDateFormatTypes = 'SSS';
  const millisecondsLong: TDateFormatTypes = 'mm:ss.SSS';
  const seconds: TDateFormatTypes = 'ss';
  const secondsLong: TDateFormatTypes = 'HH:mm:ss';
  const minuteShort: TDateFormatTypes = 'mm';
  const hourShort: TDateFormatTypes = 'HH';
  const month: TDateFormatTypes = 'MM';
  const monthLong: TDateFormatTypes = 'MMMM';
  const dayNumber: TDateFormatTypes = 'D';
  const dateNoBreaks: TDateFormatTypes = 'DDMMYYYY';

  const formatDate = (
    date?: moment.Moment | string | Date,
    format?: TMomentFormats,
    roundEndOfDay = false
  ) => {
    let result = '';
    const service = dateService(date);

    switch (format) {
      case 'key':
        result = service.format('YYYY-MM-DD');
        break;
      case 'time':
        result = service.format(timeFormat);
        break;
      case 'dayTwoLetters':
        result = service.format(dayTwoLetters);
        break;
      case 'dateYearFirst':
        result = service.format(dateYearFirst);
        break;
      case 'dateWithDayNoYear':
        result = service.format(dateWithDayNoYear);
        break;
      case 'dayNumber':
        result = service.format(dayNumber);
        break;
      case 'minuteShort':
        result = service.format(minuteShort);
        break;
      case 'hourShort':
        result = service.format(hourShort);
        break;
      case 'dayFull':
        result = service.format(dayFull);
        break;
      case 'dayShort':
        result = service.format(dayShort);
        break;
      case 'dayLong':
        result = service.format('dddd, MMMM Do, YYYY');
        break;
      case 'dateShort':
        result = service.format(dateShort);
        break;
      case 'dayShortWithDateShort':
        result = service.format(dayShortWithDateShort);
        break;
      case 'dateYearShort':
        result = service.format(dateFormatYearShort);
        break;
      case 'dayWithDate':
        result = service.format(dayWithDate);
        break;
      case 'dateTime':
        result = service.format(dateTimeFormat);
        break;
      case 'dayShortWithDate':
        result = service.format(dayShortWithDate);
        break;
      case 'fullTime':
        result = service.format('H:mm:ss');
        break;
      case 'day':
        result = service.format('ddd');
        break;
      case 'dayMonth':
        result = service.format(dayMonth);
        break;
      case 'hour':
        result = service.format('H');
        break;
      case 'year':
        result = service.format(yearFormat);
        break;
      case 'milliseconds':
        result = service.format(milliseconds);
        break;
      case 'millisecondsLong':
        result = service.format(millisecondsLong);
        break;
      case 'seconds':
        result = service.format(seconds);
        break;
      case 'secondsLong':
        result = service.format(secondsLong);
        break;
      case 'lastWeek':
        result = service.format(lastWeekFormat);
        break;
      case 'yesterday':
        result = service.format(yesterdayFormat);
        break;
      case 'month':
        result = service.format(month);
        break;
      case 'monthLong':
        result = service.format(monthLong);
        break;
      case 'weekDayDetail':
        result = service.format(weekDetailFormat);
        break;
      case 'dateNoBreaks':
        result = service.format(dateNoBreaks);
        break;
      case 'dateShortWithTime':
        result = service.format(dateShortWithTime);
        break;
      case 'date':
      case 'default':
      default:
        result = service.format(dateFormat);
        break;
    }
    return roundEndOfDay ? displayAsMidnight(result) : result;
  };

  return {
    yesterdayFormat,
    lastWeekFormat,
    dateFormat,
    dateFormatYearShort,
    dayShortWithDateShort,
    dateTimeFormat,
    dayWithDate,
    dayShortWithDate,
    dayFull,
    dayMonth,
    dateShort,
    timeFormat,
    day,
    dayShort,
    yearFormat,
    formatDate,
  };
};
