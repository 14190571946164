import moment from 'moment';

export type TDateFormatTypes =
  | 'dddd, MMMM Do, YYYY'
  | '[Week] w, MMMM YYYY'
  | '[Woche] w, MMMM YYYY'
  | 'YYYY-MM-DDTHH:mm:ss'
  | 'dddd, DD.MM.YYYY'
  | 'dddd, DD/MM/YYYY'
  | 'YYYY.MM.DD HH:mm'
  | 'YYYY/MM/DD HH:mm'
  | 'DD.MM.YYYY HH:mm'
  | 'DD/MM/YYYY HH:mm'
  | 'DD/MM HH:mm'
  | 'DD.MM HH:mm'
  | 'dd, DD.MM.YYYY'
  | 'dd, DD/MM/YYYY'
  | 'YYYY.MM.DD HH'
  | 'YYYY.MM.DD'
  | 'YYYY-MM-DD'
  | '[Yesterday] HH:mm'
  | '[Gestern] HH:mm'
  | '[Last] dddd HH:mm'
  | '[Letzten] dddd HH:mm'
  | 'DD.MM.YY'
  | 'DD/MM/YY'
  | 'DD.MM.YYYY'
  | 'DD/MM/YYYY'
  | 'ddd DD/MM'
  | 'ddd DD.MM'
  | 'dd, DD.MM'
  | 'dd, DD/MM'
  | 'DDMMYYYY'
  | 'H:mm:ss'
  | 'Y-M-D'
  | 'DD.MM.'
  | 'DD.MM'
  | 'DD/MM'
  | 'HH:mm'
  | 'ddd D'
  | 'dddd'
  | 'ddd'
  | 'dd'
  | 'H'
  | 'YYYY'
  | 'SSS'
  | 'mm:ss.SSS'
  | 'ss'
  | 'HH:mm:ss'
  | 'MM'
  | 'mm'
  | 'HH'
  | 'D'
  | 'MMMM';

interface IDateServiceReturn {
  format: (formatType?: TDateFormatTypes) => string;
  toDate: () => Date;
  isValid: () => boolean;
  isValueDate: () => boolean;
  weekday: () => number;
  value: () => moment.Moment;
}

export const dateService = (
  date?: Date | string | moment.Moment
): IDateServiceReturn => {
  const momentDate = moment(date);

  const format = (formatType: TDateFormatTypes = 'DD.MM.YYYY') => {
    return momentDate.format(formatType);
  };

  const value = () => momentDate;

  const toDate = () => momentDate.toDate();

  const isValid = () => momentDate.isValid();

  const weekday = () => momentDate.weekday();

  const isValueDate = (formatType?: TDateFormatTypes) => {
    return Boolean(
      date &&
        (moment.isMoment(date) ||
          date instanceof Date ||
          moment(date, formatType ?? 'YYYY-MM-DDTHH:mm:ssZ', true).isValid())
    );
  };

  return {
    value,
    format,
    toDate,
    isValid,
    weekday,
    isValueDate,
  };
};
