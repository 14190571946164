import { styled } from 'styled-components';
import { Utils, Theme } from 'team-hero-ui';

const { flexFn, pxToRem } = Utils;

export const MessageInputStyled = styled.div`
  background-color: ${Theme.colors.primary.grey};
  padding: ${pxToRem(24)};
  & > div {
    background-color: ${Theme.colors.primary.white};
    border-radius: ${pxToRem(4)};
  }

  .DraftEditor-root {
    .DraftEditor-editorContainer {
      .public-DraftEditor-content {
        min-height: ${pxToRem(100)} !important;
        height: ${pxToRem(100)} !important;
        overflow: auto;
      }
    }
  }
`;

export const MobileInputStyled = styled.div`
  position: relative;
  ${flexFn('flex-start', 'center', 'row')};
  padding: ${pxToRem(12)};
  gap: ${pxToRem(12)};
  & > :first-child {
    width: unset;
    position: absolute;
    bottom: ${pxToRem(12)};
    & > div {
      width: unset;
    }
  }
`;

export const MobileContentInputWrapperStyled = styled.div`
  ${flexFn('flex-start', 'stretch', 'column')};
  flex: 1;
  width: 100%;
  gap: ${pxToRem(4)};
`;

export const PlainMessageInputStyled = styled.div`
  width: 100%;
  margin-left: ${pxToRem(46)};
  && .MuiInputBase-root {
    background-color: ${Theme.colors.greyShades.grey7};
  }
`;

export const SendButtonStyled = styled.div`
  align-self: center;
`;

export const SubjectStyled = styled.div`
  margin-bottom: ${pxToRem(24)};
  && {
    padding: 0;
  }
`;

export const SubjectMobileStyled = styled.div`
  padding: ${pxToRem([0, 16])};
  && .MuiInputBase-root {
    background-color: ${Theme.colors.greyShades.grey7};
  }
  && .MuiFormLabel-root {
    font-size: ${pxToRem(13)};
  }
`;
