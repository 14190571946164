import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { IShiftRightLegendContentProps } from 'components/WorkPlanner/components/WorkPlannerTimelineView/components/legendRenderer/RightLegend/ShiftRightLegendContent/ShiftRightLegendContent.type';
import {
  InnerColumnStyled,
  InnerStyled,
  LegendWrapperStyled,
  TextStyled,
} from 'components/WorkPlanner/components/WorkPlannerTimelineView/components/legendRenderer/RightLegend/ShiftRightLegendContent/ShiftRightLegendContent.styled';
import { getOutputNumber } from 'helpers/number/numberToString.helper';

const ShiftRightLegendContent: FC<IShiftRightLegendContentProps> = ({
  width,
  hoursTotal,
}) => {
  const { t } = useTranslation('general');
  return (
    <LegendWrapperStyled $width={width}>
      <InnerStyled>
        <InnerColumnStyled>
          <TextStyled>
            {getOutputNumber(hoursTotal, 1)} {t('hourShort')}
          </TextStyled>
        </InnerColumnStyled>
      </InnerStyled>
    </LegendWrapperStyled>
  );
};

export default ShiftRightLegendContent;
