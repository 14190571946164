import { Theme, Utils } from 'team-hero-ui';
import { css } from 'styled-components';

const { pxToRem } = Utils;

const ButtonWithoutStyle = css`
  &&& {
    background: none;
    height: auto;
    border: none;
    &:hover {
      background: none;
      box-shadow: none;
    }
    & .MuiButton-label {
      font-size: ${pxToRem(11)};
      color: ${({ theme }) => theme.colors.greyShades.grey3};
    }

    &&& .MuiTouchRipple-rippleVisible {
      color: transparent;
    }
  }
`;

const shadowDark = css`
  box-shadow: 0 0 4px 2px ${Theme.colors.greyShades.grey1};
`;
const shadowLight = css`
  box-shadow: 0 0 4px 2px ${Theme.colors.greyShades.grey5};
`;
const shadowLight1 = css`
  box-shadow: 0 0 6px 2px ${Theme.colors.greyShades.grey4};
`;

const shadowLight2 = css`
  box-shadow: 0 -1px 2px 0 ${Theme.colors.greyShades.grey5};
`;

export {
  ButtonWithoutStyle,
  shadowDark,
  shadowLight,
  shadowLight1,
  shadowLight2,
};
