import { styled } from 'styled-components';
import { Theme, Utils } from 'team-hero-ui';

const { flexFn, pxToRem } = Utils;

export const WrapperStyled = styled.div`
  position: relative;
  ${flexFn('flex-start', 'center', 'column')};
  gap: ${pxToRem(5)};
  width: 100%;
  max-width: ${pxToRem(40)};
`;

export const NumberOfParticipantsStyled = styled.div`
  ${flexFn('center', 'center', 'row')};
  font-size: ${pxToRem(11)};
  color: ${Theme.colors.greyShades.grey3};
  padding: ${pxToRem(4)};
  border-radius: ${pxToRem(12)};
  min-width: ${pxToRem(24)};
  background-color: ${Theme.colors.greyShades.grey5};
`;
