import { styled } from 'styled-components';
import { Utils } from 'team-hero-ui';

const { pxToRem, flexFn } = Utils;

export const ToDoCellContainerStyled = styled.div`
  ${flexFn()}
`;

export const TodoIconSectionContainerStyled = styled.div`
  ${flexFn()}
  gap: ${pxToRem(5)};
  margin-right: ${pxToRem(20)};
`;
