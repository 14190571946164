import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Icons, WorkplannerCard } from 'team-hero-ui';

import { useDateFormat } from 'hooks/useDateFormat.hook';
import { getOutputNumber } from 'helpers/number/numberToString.helper';
import { ITimelineShiftItemProps } from 'components/Timeline/interfaces/timeline.interface';
import { getDurationAsHoursByMoment } from 'components/Timeline/helper/timelineItemsCalculate.helper';
import { checkSpansFullDay } from 'helpers/dateTime/startOfEndOf.helper';
import {
  ConnectedIconWrapperStyled,
  TimelineItemAvatarListWrapper,
  TimelineItemInnerBottomStyled,
  TimelineItemInnerStyled,
  TimelineItemInnerTopStyled,
  TimelineTimeDetailStyled,
  TimelineTimeDetailTextStyled,
} from '../itemRenderer.styled';
import AvatarStatusRenderer from '../AvatarStatusRenderer';
import useClientLoginMissionSettings from 'hooks/useClientLoginMissionSettings.hook';

const TimelineItemProjectShift: FC<ITimelineShiftItemProps> = ({
  timelineItem,
  left,
  cellWidth,
  top,
  height,
  missionId,
}) => {
  const { formatDate } = useDateFormat();
  const { t } = useTranslation();

  const { enableClientContacts } = useClientLoginMissionSettings({ missionId });

  const { id, start, end, item } = timelineItem;
  const duration = getDurationAsHoursByMoment(start, end);

  const durationDays = Math.floor(
    moment.duration(moment(end).diff(moment(start).startOf('day'))).asDays()
  );

  return (
    <WorkplannerCard
      status={'deactivated'}
      innerMinWidth={220}
      left={left}
      width={cellWidth}
      top={top}
      height={height}
      key={id}
    >
      <TimelineItemInnerStyled>
        <TimelineItemInnerTopStyled>
          <span>
            {getOutputNumber(duration)}
            {t('hourShort')}
          </span>
          {item.linkedPosition && (
            <ConnectedIconWrapperStyled>
              <Icons.ConnectedIcon svgSize={16} svgColor='currentColor' />
            </ConnectedIconWrapperStyled>
          )}
        </TimelineItemInnerTopStyled>
        <TimelineTimeDetailStyled>
          <TimelineTimeDetailTextStyled>
            {durationDays > 0 && (
              <>
                {formatDate(start, 'dateShort')} -{' '}
                {formatDate(end, 'dateShort')}{' '}
              </>
            )}
            {!checkSpansFullDay(start, end) ? (
              <>
                {formatDate(start, 'time')} - {formatDate(end, 'time')}
              </>
            ) : (
              <>&nbsp;</>
            )}
          </TimelineTimeDetailTextStyled>
        </TimelineTimeDetailStyled>
        <TimelineItemInnerBottomStyled>
          {enableClientContacts && (
            <TimelineItemAvatarListWrapper>
              {item.contact && (
                <AvatarStatusRenderer shift={item} cardStatus={item.status} />
              )}
            </TimelineItemAvatarListWrapper>
          )}
        </TimelineItemInnerBottomStyled>
      </TimelineItemInnerStyled>
    </WorkplannerCard>
  );
};

export default TimelineItemProjectShift;
