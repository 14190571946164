import { FC, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ConversationListItem, Pagination } from 'team-hero-ui';

import {
  paginatedConversationsSelector,
  setNextPage,
  setItemsPerPage,
} from 'redux/collections/conversations/conversations.slice';
import useCurrentUserContact from 'hooks/useCurrentUserContact.hook';
import {
  ConversationItemStyled,
  ConversationListItemsStyled,
  ConversationListStyled,
  ConversationListWrapperStyled,
} from './ClientLoginConversationList.styled';
import { PaginationStyled } from 'components/Modals/Modals.styled';
import { useAppSelector } from 'store/hooks';
import { calculatePagesCount } from 'pages/Work/Work.helpers';
import { usePagination } from 'hooks/usePagination.hook';
import { useDateFormat } from 'hooks/useDateFormat.hook';
import { getProjectAndMissionTitle } from 'pages/Messages/Messages.helper';
import { IClientLoginConversationListProps } from './ClientLoginConversationList.types';
import ParticipantMinCellRenderer from 'components/cellRenderers/ParticipantMinCellRenderer';
import NoResultGraphic from 'components/NoResultGraphic';

const ClientLoginConversationList: FC<IClientLoginConversationListProps> = ({
  conversations,
  conversationsTotalCount,
  onConversationClick,
}) => {
  const [searchParams] = useSearchParams();
  const conversationId = searchParams.get('conversation') || '';

  const { formatDate } = useDateFormat();

  const { currentUserContact } = useCurrentUserContact();

  const { pagination } = useAppSelector(paginatedConversationsSelector);

  const { page, itemsPerPage, onChangePage } = usePagination(
    pagination.currentPage,
    setNextPage,
    setItemsPerPage,
    paginatedConversationsSelector
  );

  const getCreatedAtDate = useCallback(
    (createdAt?: string) => {
      if (!createdAt) {
        return null;
      }

      return formatDate(createdAt, 'dateWithDayNoYear');
    },
    [formatDate]
  );

  return (
    <ConversationListStyled>
      <ConversationListWrapperStyled data-test-id='conversations-wrapper'>
        <ConversationListItemsStyled>
          {conversations.length === 0 && <NoResultGraphic />}
          {conversations.map((conversation) => {
            const isSentMessage =
              conversation.lastMessage?.fromContact?.id ===
              currentUserContact?.id;
            const { missionTitle, projectTitle } =
              getProjectAndMissionTitle(conversation);

            return (
              <ConversationItemStyled
                key={conversation.id}
                onClick={() => onConversationClick(conversation.id)}
              >
                <ConversationListItem
                  isRead={conversation.totalUnreadMessages === 0}
                  avatar={
                    <ParticipantMinCellRenderer conversation={conversation} />
                  }
                  message={
                    conversation.lastMessage?.subject ||
                    conversation.lastMessage?.content
                  }
                  isSelected={conversationId === conversation.id.toString()}
                  isSentMessage={isSentMessage}
                  createdAt={getCreatedAtDate(
                    conversation.lastMessage?.createdAt
                  )}
                  name={
                    conversation.isGroupChat
                      ? conversation.title
                      : conversation.lastMessage?.fromContact?.firstName
                  }
                  title={
                    missionTitle && projectTitle
                      ? `${missionTitle} ${projectTitle}`
                      : missionTitle || projectTitle
                  }
                />
              </ConversationItemStyled>
            );
          })}
        </ConversationListItemsStyled>
        <PaginationStyled>
          {!!conversationsTotalCount && (
            <Pagination
              page={page}
              onChange={onChangePage}
              count={calculatePagesCount(
                itemsPerPage,
                conversationsTotalCount || 1
              )}
            />
          )}
        </PaginationStyled>
      </ConversationListWrapperStyled>
    </ConversationListStyled>
  );
};

export default ClientLoginConversationList;
